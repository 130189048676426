import gsap from 'gsap'

export const scaleIn = (ref: gsap.TweenTarget, duration: number = 1) => {
  return gsap
    .fromTo(
      ref,
      {
        scale: 0
      },
      {
        scale: 1
      }
    )
    .duration(duration)
}

export const fadeIn = (ref: gsap.TweenTarget, duration: number = 1) => {
  return gsap
    .fromTo(
      ref,
      {
        zIndex: 1,
        opacity: 0
      },
      {
        opacity: 1
      }
    )
    .duration(duration)
}

export const fadeOut = (ref: gsap.TweenTarget, duration: number = 1) => {
  return gsap
    .fromTo(
      ref,
      {
        zIndex: -1,
        opacity: 1
      },
      {
        opacity: 0
      }
    )
    .duration(duration)
}
