import { TFunction } from 'next-i18next'

export const getSections = (t: TFunction) => [
  {
    id: 1,
    texts: t<string[]>('hero.tulmeretes_szallitas.texts', {
      returnObjects: true
    }),
    imageUrl: '/assets/images/hero/tulmeretes-szallitas.webp',
    position: {
      top: '30%',
      left: '10%'
    }
  },
  {
    id: 2,
    texts: t<string[]>('hero.munkagepek_szallitasa.texts', {
      returnObjects: true
    }),
    imageUrl: '/assets/images/hero/munkagepek-szallitasa.webp',
    position: {
      align: 'end',
      top: '20%',
      right: '10%'
    }
  },
  {
    id: 3,
    texts: t<string[]>('hero.kamionok_szallitasa.texts', {
      returnObjects: true
    }),
    imageUrl: '/assets/images/hero/kamionok-szallitasa.webp',
    position: {
      top: '40%',
      left: '10%'
    }
  },
  {
    id: 4,
    texts: t<string[]>('hero.hosszu_aruk_hidelemek_szallitasa.texts', {
      returnObjects: true
    }),
    imageUrl: '/assets/images/hero/hosszu-aruk-hidelemek-szallitasa.webp',
    position: {
      align: 'end',
      top: '30%',
      right: '10%'
    }
  }
]
