import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  HeroSections,
  HeroText,
  HeroTextContainer,
  IconButton,
  Img,
  SwitchButtons
} from './styles'
import { AnimatedHeroSectionProps, TextPosition } from './types'
import { fadeIn, fadeOut, scaleIn } from './animations'
import { getSections } from './sections'
import { useTranslation } from 'next-i18next'

const delay = 6000

export default function Hero() {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)
  let textList = useRef<(HTMLDivElement | null)[]>([])
  let imageList = useRef<(HTMLDivElement | null)[]>([])
  let switchButtonList = useRef<(HTMLDivElement | null)[]>([])

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const timeline = useRef(gsap.timeline())

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(handleNext, delay)

    return () => {
      resetTimeout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  const animateSection = (prevIndex: number, nextIndex: number) => {
    timeline.current.clear()
    const images = imageList.current
    const texts = textList.current
    const buttons = switchButtonList.current

    setCurrentIndex(nextIndex)
    timeline.current
      .add(fadeIn(images[nextIndex]))
      .add(fadeOut(images[prevIndex]))
      .add(fadeIn(texts[nextIndex], 1), 0.5)
      .add(scaleIn(texts[nextIndex], 1), 0)
      .fromTo(
        buttons[nextIndex],
        { opacity: 0, x: -10 },
        { opacity: 1, x: 0 },
        1
      )
  }

  const handlePrev = () => {
    const isFirst = currentIndex === 0
    const index = isFirst ? imageList.current.length - 1 : currentIndex - 1
    animateSection(currentIndex, index)
  }

  const handleNext = () => {
    const isLast = currentIndex === imageList.current.length - 1
    const index = isLast ? 0 : currentIndex + 1
    animateSection(currentIndex, index)
  }

  const heroSections = getSections(t)

  return (
    <HeroSections>
      {heroSections.map((section, index) => (
        <AnimatedHeroSection
          titleRef={(el) => (textList.current[index] = el)}
          imageRef={(el) => (imageList.current[index] = el)}
          buttonGroupRef={(el) => (switchButtonList.current[index] = el)}
          key={section.id}
          texts={section.texts}
          imageUrl={section.imageUrl}
          focused={currentIndex === index}
          index={index}
          position={section.position as TextPosition}
          onPrevClick={handlePrev}
          onNextClick={handleNext}
        />
      ))}
    </HeroSections>
  )
}

function AnimatedHeroSection({
  titleRef,
  imageRef,
  buttonGroupRef,
  texts,
  imageUrl,
  focused,
  index,
  position,
  onPrevClick,
  onNextClick
}: AnimatedHeroSectionProps) {
  const zIndex = focused ? 1 : -index

  return (
    <>
      <Img ref={imageRef} imageUrl={imageUrl} style={{ zIndex }} />
      <HeroTextContainer
        ref={titleRef}
        position={position}
        style={{
          zIndex: zIndex
        }}
      >
        {texts.map((text) => (
          <HeroText key={text}>{text}</HeroText>
        ))}
        <SwitchButtons ref={buttonGroupRef}>
          <IconButton onClick={onPrevClick}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={onNextClick}>
            <ArrowForwardIcon />
          </IconButton>
        </SwitchButtons>
      </HeroTextContainer>
    </>
  )
}
