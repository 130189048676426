import styled, { css } from 'styled-components'
import { H1 } from '../../style-system/typography'
import { fluidFontSize } from '../../style-system/utils'
import { TextPosition } from './types'

export const HeroSections = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`

export const SwitchButtons = styled.div`
  display: flex;
  width: 90px;
  margin: 20px 0;
  z-index: 10;
  justify-content: space-between;
`

export const IconButton = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 30px;
  cursor: pointer;
  transition: opacity 300ms ease;
  :hover {
    opacity: 0.6;
  }
  :active {
    opacity: 0.4;
  }
`

export const HeroTextContainer = styled.div<{ position: TextPosition }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  ${({ position }) => css`
    ${position};
    align-items: flex-${position.align};
    text-align: ${position.align};
  `}
  @media ${({ theme }) => theme.query.md} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
  }
`

export const HeroText = styled(H1)`
  padding: 0;
  margin: 0;
  font-family: LobsterTwo-BoldItalic;
  letter-spacing: 5px;
  font-size: ${fluidFontSize([30, 36, 48, 56])};
  text-shadow: 0px 0px 5px ${({ theme }) => theme.colors.darkGray};
`

export const Img = styled.div<{ imageUrl: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
`
