import type { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import HeroSection from '../components/HeroSection'
import References from '../components/References'
import Services from '../components/Services'
import WhyUs from '../components/WhyUs'

const Home: NextPage = () => {
  return (
    <>
      <HeroSection />
      <Services />
      <WhyUs />
      <References />
    </>
  )
}

export default Home

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['main']))
    }
  }
}
